import React, { useState, useEffect } from 'react';
import CardGame from './CardGame';
import StandaloneGame from './StandaloneGame';
import Login from './Login'
import './App.css';
import logo from './img/logo.png';

function App() {

  const [token, setToken] = useState('');
  const [expiration, setExpiration] = useState('');
  const [bypassToken, setBypassToken] = useState(false);
  const [mode, setMode] = useState('');

  useEffect(() => {

    async function getToken() {
      let storedToken = sessionStorage.getItem("token");
      if (storedToken) {
        let json = JSON.parse(storedToken);
        setToken(json.access_token);

        let expirationDate = sessionStorage.getItem("expiration");
        setExpiration(expirationDate);
      }

      let bypass = sessionStorage.getItem("bypass");
      if (bypass) {
        setBypassToken(true);
      }
    }

    getToken();

  }, []);

  function renderPage() {
    if (token === '' && !bypassToken) {
      return <Login/>
    } else {
      if (mode === '') {
        return (
          <div className="App">
            <header className="App-header">
                <img className="logo" src={logo} alt="Logo" />
                <button className='btn-spotify' onClick={() => setMode('card')}>
                  Play with QR Cards
                </button>
                <button className='btn-spotify' onClick={() => setMode('standalone')}>
                  Play Standalone
                </button>
              </header>
          </div>
        );
      } else {
        if (mode === 'card') {
          return <CardGame token={token} expiration={expiration} bypass={bypassToken} />
        } else {
          return <StandaloneGame token={token} expiration={expiration} bypass={bypassToken} />
        }
      }
    }
  }

  return (
    <>
        <div className='header'>
          <div className='title'>(Holiday Hitster)</div>
        </div>
        {renderPage()}
    </>
  );
}


export default App;
