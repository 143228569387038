import React, { useState, useEffect } from 'react';
import { 
    IconPlayerPlayFilled, 
    IconRotateClockwise, 
    IconArrowBigUpFilled, 
    IconArrowBigDownFilled, 
    IconCircleCheckFilled, 
    IconPlayerTrackNextFilled, 
    IconMusic,
    IconFlag,
    IconGiftFilled,
    IconArrowRightTail,
    IconShare2
} from '@tabler/icons-react';
import charlie from './img/charlie.jpg';
import jsonData from './data/holidayhitster.json';

function StandaloneGame(props) {
    //TODO
    // Help/About page

    const [tracks, setTracks] = useState([]);
    const [trackIndex, setTrackIndex] = useState(0)
    const [trackData, setTrackData] = useState(null);
    const [reveal, setReveal] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);

    const [gifts, setGifts] = useState(3);
    const [score, setScore] = useState(0);
    const [cardClass, setCardClass] = useState('');
    const [gameOver, setGameOver] = useState(false);

    const [yearIndex, setYearIndex] = useState(0);
    const [years, setYears] = useState([]);

    const [logEnabled] = useState(false);
    const [log, setLog] = useState([]);

    useEffect(() => {

        const shuffle = (array) => {
            for (var i = array.length - 1; i > 0; i--) {
              var j = Math.floor(Math.random() * (i + 1));
              var temp = array[i];
              array[i] = array[j];
              array[j] = temp;
            }
        };

        let startSongUri = '';
        if (tracks.length === 0) {
            if (props.bypass) {
                let tracksArr = jsonData;
                shuffle(tracksArr);
                setTracks(tracksArr);
                setTrackData(tracksArr[trackIndex]);
                
                startSongUri = tracksArr[trackIndex].uri;

                if (window.controller) {
                    window.controller.loadUri(startSongUri);
                    setTimeout(() => {
                        window.controller.togglePlay();
                    }, 1000); 
                } else {
                    window.onSpotifyIframeApiReady = (IFrameAPI) => {
                        
                        const element = document.getElementById('embed-iframe');
                        if (element) {
                            
                            const options = {
                                uri: startSongUri
                            };
                            const callback = (EmbedController) => {
                                window.controller = EmbedController;
                                document.querySelectorAll('.nextBtn').forEach(
                                    btn => {
                                        btn.addEventListener('click', () => {
                                            //placeholder
                                        });
                                    }
                                );
                                setTimeout(() => {
                                    EmbedController.togglePlay();
                                }, 500);                        
                            };
                            IFrameAPI.createController(element, options, callback);
                        }
                    };
                }
            } else {
                //let playlistId = '37i9dQZF1DX0Yxoavh5qJV';// Christmas Hits
                let playlistId = '5kDTHiDMen7s2ougmPVHJI';// Holiday Hitster
                fetch('https://api.spotify.com/v1/playlists/' + playlistId, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + props.token
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        // logMsg('API failed: ' + response.status);
                        console.log('api error');
                        if (response.statusText) {
                            // logMsg(response.statusText);
                        }
                    }
                    return response.json()
                })
                .then(data => {         
                    let tracksArr = jsonData;
                    if (!data.error) {
                        //console.log('using api result');
                        let logData = data.tracks.items.map(function(item) {
                            return {
                                "uri": item.track.uri,
                                "name": item.track.name,
                                "album": {
                                    "release_date": item.track.album.release_date
                                },
                                "artists": [
                                    {
                                        "name": item.track.artists[0].name
                                    }
                                ]
                            }
                        });
                        console.log(logData);
                    }
                    
                    shuffle(tracksArr);
                    setTracks(tracksArr);
                    setTrackData(tracksArr[trackIndex]);
                    
                    startSongUri = tracksArr[trackIndex].uri;

                    if (window.controller) {
                        window.controller.loadUri(startSongUri);
                        setTimeout(() => {
                            window.controller.togglePlay();
                        }, 1500); 
                    } else {
                        window.onSpotifyIframeApiReady = (IFrameAPI) => {
                            
                            const element = document.getElementById('embed-iframe');
                            if (element) {
                                
                                const options = {
                                    uri: startSongUri
                                };
                                const callback = (EmbedController) => {
                                    window.controller = EmbedController;
                                    document.querySelectorAll('.nextBtn').forEach(
                                        btn => {
                                            btn.addEventListener('click', () => {
                                                //placeholder
                                            });
                                        }
                                    );
                                    setTimeout(() => {
                                        EmbedController.togglePlay();
                                    }, 1000);                        
                                };
                                IFrameAPI.createController(element, options, callback);
                            }
                        };
                    }
                });
            }
        }
        
    }, [props.token, props.bypass, trackIndex, tracks.length]);

    function logMsg(msg) {
        if (logEnabled) setLog([...log, msg]);
    }

    function revealAnswer() {
        logMsg('Reveal clicked!');
        setReveal(true);
        
        if (!trackData) {
            setShowPlayer(true);
        }
    }

    function downClicked() {
        if (!reveal && yearIndex < years.length) {
            setYearIndex(yearIndex + 1);
        }
    }

    function upClicked() {
        if (!reveal && yearIndex > 0) {
            setYearIndex(yearIndex - 1);
        }
    }

    function submitClicked() {

        if (reveal) return;
        
        let beforeWrong = false;
        let afterWrong = false;
        let trackYear = parseInt(trackData.album.release_date.substring(0, 4));
        if (yearIndex > 0) {
            let beforeYear = years[yearIndex - 1];
            if (trackYear < beforeYear) beforeWrong = true;
        }
        if (yearIndex < years.length) {
            let afterYear = years[yearIndex];
            if (trackYear > afterYear) afterWrong = true;
        }
        let isWrong = beforeWrong || afterWrong;
        if (isWrong) {
            if (gifts - 1 === 0) {
                setTimeout(() => {
                    setGameOver(true);
                    window.controller.loadUri('spotify:track:3Y9tu0vssINKFcA90JiLHb');
                    setTimeout(() => {
                        window.controller.togglePlay();
                    }, 500);
                }, 5000);
            }
            setGifts(gifts - 1);
            setCardClass('wrong');
        } else {
            setScore(score + 1);
            setYears([
                ...years.slice(0, yearIndex),
                trackYear,
                ...years.slice(yearIndex)
            ]);
            setCardClass('right');
        }

        revealAnswer();
    }

    function nextClicked () {
        if (!reveal) return;
        setCardClass('');
        setYearIndex(0);
        setTrackData(tracks[trackIndex+1]);
        setTrackIndex(trackIndex+1);
        setReveal(false);
        setShowPlayer(false);
        window.controller.loadUri(tracks[trackIndex+1].uri);
        setTimeout(() => {
            window.controller.togglePlay();
        }, 500);
    }

    function restartClicked() {
        window.controller.restart();
    }

    function togglePlayClicked() {
        window.controller.togglePlay();
    }

    function playAgainClicked() {
        window.location.reload();
    }

    function shareClicked() {
        let resultsToCopy = "Happy Holidays!\r\nI just got a score of " + score + " playing Holiday Hitster.\r\n☃️\r\n";
        resultsToCopy += "\r\nhttps://hitster.zachwerden.com";
        resultsToCopy += "\r\n\r\n";

        if (navigator.share) {
            navigator.share({
                text: resultsToCopy
            });
        } else {
            navigator.clipboard.writeText(resultsToCopy);
        }
    }

    function reportSong(e) {
        let mailto = "mailto:zachmagic@gmail.com?subject=Holiday%20Hitster%3A%20Flagging%20Song&body=I%20am%20reporting%20the%20following%20song%3A%0A" + trackData.name + " ("+ trackData.id + ")%0A%0AReason%3A%20";
        window.location.href = mailto;
        e.preventDefault();
    }

    function renderButtons() {
        if (gameOver) {
            return (
                <div className='player-btn-wrapper'>
                    <button className='btn-player restartBtn btn-spotify' onClick={playAgainClicked}>
                        <IconRotateClockwise></IconRotateClockwise>
                    </button>
                    <button className='btn-player playBtn btn-spotify' onClick={shareClicked}>
                        <IconShare2></IconShare2>
                    </button>
                </div>
            );
        };
        if (gifts === 0) return;
        let nextDisabledClass = reveal ? '' : ' disabled';
        let timelineDisabledClass = reveal ? ' disabled' : '';
        return (
            <>
                <div className='player-btn-wrapper'>
                    <button className='btn-player restartBtn btn-spotify' onClick={restartClicked}>
                        <IconRotateClockwise></IconRotateClockwise>
                    </button>
                    <button className='btn-player playBtn btn-spotify' onClick={togglePlayClicked}>
                        <IconPlayerPlayFilled></IconPlayerPlayFilled>
                    </button>
                    <button className={'btn-player playBtn btn-spotify' + nextDisabledClass} onClick={nextClicked}>
                        <IconPlayerTrackNextFilled></IconPlayerTrackNextFilled>
                    </button>
                </div>
                <div className='btn-wrapper'>
                    <button className={'nextBtn btn-spotify' + timelineDisabledClass} onClick={upClicked}><IconArrowBigUpFilled></IconArrowBigUpFilled></button> 
                    <button className={'nextBtn btn-spotify' + timelineDisabledClass} onClick={downClicked}><IconArrowBigDownFilled></IconArrowBigDownFilled></button> 
                    <button className={'nextBtn btn-spotify' + timelineDisabledClass} onClick={submitClicked}><IconCircleCheckFilled></IconCircleCheckFilled></button>               
                </div>
            </>
            
        );
    }

    function renderPlayer() {
        return (
            <div id="embed-wrapper" className={showPlayer ? 'showPlayer' : ''}>
                <div id="embed-iframe"></div>
            </div>
        );
    }

    function renderGifts() {
        let giftElms = [];
        for (let index = 0; index < gifts; index++) {
            giftElms.push(
                (
                    <IconGiftFilled size={32} color='#E03636'></IconGiftFilled>
                )
            );
        }
        return (
            <div id="gift-wrapper">
                {giftElms}
            </div>
        );
    }

    function renderCard() {
        if (gameOver) {
            return (
                <div id="embed-overlay">
                    <div id="game-over">Game Over</div>
                    <img className="charlie" src={charlie} alt="Sad Charlie" />
                </div>
            );
        }
        if (reveal) {
            if (trackData) {
                let title = trackData.name;
                if (title.length > 38) {
                    title = title.substring(0, 38) + '...';
                }
                return (
                    <div id="embed-overlay" className={cardClass}>
                        <IconFlag onClick={reportSong} className="flag"></IconFlag>
                        <div id="reveal-artist">{trackData.artists[0].name}</div>
                        <div id="reveal-year">{trackData.album.release_date.substring(0, 4)}</div>
                        <div id="reveal-track">{title}</div>
                    </div>
                );
            }               
        } else {
            return (
                <div id="embed-overlay">
                    <div id="reveal-prompt"><IconMusic size={64}></IconMusic></div>
                </div>
            );
        }
    }

    function renderYears() {
        let yearElms = years.map(function(yr) {
            return (
              <div className="yr">
                {yr}
              </div>
            )
          });
        
        if (!gameOver) {
            let cursor = (
                <div className="yr arrow">
                    <IconArrowRightTail color='#CBA95D'></IconArrowRightTail>
                </div>
            );
            yearElms.splice(yearIndex, 0, cursor);
        }

        return yearElms;
    }

    function renderScore() {
        return (
            <div className="score">{score}</div>
        );
    }

    function renderLog() {
        if (logEnabled) {
            var messages = log.map(function(msg) {
                return (
                  <div className="log-msg">
                    {msg}
                  </div>
                )
              });
            return (
                <div id="log">
                    {messages}
                </div>
            );
        }
    }

    return (
        <div className='wrapper standalone'>
            <div className='main'>
                {renderGifts()}
                {renderCard()}
                {renderButtons()}
            </div>
            <div className='years'>
                {renderScore()}
                <div className="oldnew">older</div>
                {renderYears()}
                <div className="oldnew">newer</div>                
            </div>

            {renderPlayer()}

            {renderLog()}
        </div>
    );
}

export default StandaloneGame
